import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import appStyle from './app.module.scss'

import HomeScreen from "./screens/HomeScreen";
import ServerListScreen from "./screens/serverlist/ServerListScreen";
import AccountScreen from "./screens/AccountScreen";
import HelpScreen from "./screens/HelpScreen";
import NavbarComponent from "./components/Navbar/NavbarComponent";

const routes = [
  {
    path: "/serverlist",
    component: ServerListScreen
  },
  {
    path: "/lobby/:id",
    component: ServerListScreen
  },
  {
    path: "/game/:id",
    component: ServerListScreen
  },
  {
    path: "/account",
    component: AccountScreen
  },
  {
    path: "/help",
    component: HelpScreen
  },
  { // need to be last
    path: "/",
    component: HomeScreen
  },
];

export default function App() {
  return (
      <Router>
        <div className={appStyle.container}>
          <div className={appStyle.content}>
            <Switch>
              {routes.map((route, i) => (
                  <Route
                      path={route.path}
                      key={i}
                      render={props => (
                          <route.component {...props} />
                      )}
                  />
              ))}
            </Switch>
          </div>
          <div className={appStyle.menuBar}>
            <NavbarComponent/>
          </div>
        </div>
      </Router>
  );
}