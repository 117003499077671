import React from "react";
import {Link} from "react-router-dom";
import NavbarStyle from './Navbar.module.scss'

export default function NavbarComponent() {
    return (
        <ul className={NavbarStyle.bar}>
            <li>
                <Link to="/">Home</Link>
            </li>
            <li>
                <Link to="/serverlist">Game</Link>
            </li>
            <li>
                <Link to="/account">Account</Link>
            </li>
            <li>
                <Link to="/help">Help</Link>
            </li>
        </ul>
    );
}
